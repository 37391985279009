// Carosusel
$(document).ready(function () {

    // $("#slider1").roundSlider({
    //     radius: 240,
    //     circleShape: "pie",
    //     sliderType: "min-range",
    //     showTooltip: true,
    //     startAngle: 315,
    //     min: 1000,
    //     max: 10000,
    //     value: 2500,
    //     handleSize: "+28",
    //     width: 27,
    //     tooltipFormat: ({ value: val }) => `${val + ""}`,
    //     drag: function (event) {
    //         // This function will be called continuously during slider drag
    //         updateSum(event.value);
    //     }
    // });

    // function updateSum(value) {
    //     // Update the content of the .rslider__sum div with the real-time value of the slider
    //     const sumDiv = $(".rslider__sum");
    //     sumDiv.text(value);
    // }
// Funkcja sprawdzająca i aktualizująca wartość parametru radius w zależności od szerokości ekranu
function updateRadius() {
    const slider = $("#slider1");
    const newRadius = window.innerWidth < 580 ? 140 : 240;

    // Ustawienie nowej wartości parametru radius
    slider.roundSlider("option", "radius", newRadius);
}

// Funkcja aktualizująca zawartość .rslider__sum div zgodnie z wartością slidera
function updateSum(value) {
    const sumDiv = $(".rslider__sum");
    const sumDiv2 = $(".tests");

    sumDiv.text(value);
    sumDiv2.text(value  / 100);



}





// Inicjalizacja slidera
$("#slider1").roundSlider({
    radius: 240,
    circleShape: "pie",
    sliderType: "min-range",
    showTooltip: true,
    startAngle: 315,
    min: 1000,
    max: 10000,
    value: 2500,
    handleSize: "+28",
    width: 18,
    tooltipFormat: ({ value: val }) => `${val + ""}`,
    drag: function (event) {
        // Ta funkcja będzie wywoływana ciągle podczas przeciągania slidera
        updateSum(event.value);
    },
    change: function (event) {
        // Ta funkcja będzie wywoływana przy zmianie wartości slidera (również przy ręcznym wprowadzaniu wartości)
        updateSum(event.value);
    }
});

// Inicjalizacja po załadowaniu strony
$(document).ready(function () {
    // Wywołanie funkcji aktualizującej parametr radius na początku
    updateRadius();

    // Dodanie nasłuchiwacza zdarzenia zmiany rozmiaru okna
    $(window).on("resize", updateRadius);
});


    // $('.hero__list').owlCarousel({
    //     center: true,
    //     items:2,
    //     loop:true,
    //     margin:40,

    //     responsive:{
    //         600:{
    //             items:4,
    //                mouseDrag: false,
    //         }
    //     },
    //     autoplay: true,
    //     autoplayTimeout: 2000,
    //     autoplayHoverPause: false
    // });



    // $('.caruzel_test').owlCarousel({
    //     center: true,
    //     items:2,
    //     loop:true,
    //     margin:40,

    //     responsive:{
    //         600:{
    //             items:4,
    //                mouseDrag: false,
    //         }
    //     },
    //     autoplay: true,
    //     autoplayTimeout: 2000,
    //     autoplayHoverPause: false
    // });


    // $('.adwords__carousel').owlCarousel({

    //     items:2,
    //     loop:true,




    //     autoplayTimeout: 2000,
    //     autoplayHoverPause: false,

    //     responsive:{

    //         768:{
    //             items:3,
    //             autoplay: false
    //         },

    //         992:{
    //             items:4,
    //             autoplay: false,
    //             mouseDrag: false,
    //         },

    //         1200:{
    //             items:5,
    //             autoplay: false,
    //             mouseDrag: false,
    //         },
    //     }

    // });

    $('.articles__carousel').owlCarousel({

        loop:true,
        margin:20,
        center: true,
        nav: false,

        responsive:{
           0:{
                items:2,
                mouseDrag: false,
                touchDrag: true,
                autoWidth: true,

            },
            768:{
                items:2,
                mouseDrag: false,
                touchDrag: true,

                autoplay: false,
            },
            1200:{
                items:4,
                mouseDrag: false,
                touchDrag: false,
                center: true,

                autoplay: true,
            }
        },


        autoplayTimeout: 2000,

    });

    $('.reviews__carousel').owlCarousel({

        loop:true,
        margin:20,
        center: true,
        autoplay: false,
        mouseDrag: false,

        responsive:{
           0:{
                items:2,
                autoWidth: true,
                touchDrag: true,

            },
            768:{
                items:2,
                touchDrag: true,


            },
            1200:{
                items:4,
                touchDrag: false,
            }
        },


    });

    $('.owl-prev').click(function() {
        $('.reviews__carousel').trigger('prev.owl.carousel');
      });

      $('.owl-next').click(function() {
        $('.reviews__carousel').trigger('next.owl.carousel');
      });







      $('.recent__carousel').owlCarousel({

        loop:true,
        margin:20,

        autoplay: false,
        mouseDrag: false,

        responsive:{
           0:{
                items:2,
                autoWidth: true,
                touchDrag: true,
                center: true,

            },
            768:{
                items:2,
                touchDrag: true,


            },
            1200:{
                items:3,
                touchDrag: false,
            }
        },


    });

    $('.owl-prev').click(function() {
        $('.recent__carousel').trigger('prev.owl.carousel');
      });

      $('.owl-next').click(function() {
        $('.recent__carousel').trigger('next.owl.carousel');
      });



});


// Cuntbox

$(document).ready(function() {
    var meetUsElement = $('.count-box');
    var hasStartedCounting = false;

    function isElementInViewport(element) {
        var elementTop = element.offset().top;
        var elementBottom = elementTop + element.height();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    function startCounting() {
        if (isElementInViewport(meetUsElement) && !hasStartedCounting) {
            hasStartedCounting = true;
            $('.count').each(function() {
                $(this).prop('Counter', 0).animate({
                    Counter: $(this).text()
                }, {
                    duration: 3000,
                    easing: 'swing',
                    step: function(now) {
                        $(this).text(Math.ceil(now));
                    }
                });
            });
        }
    }

    $(window).on('scroll', function() {
        startCounting();
    });

    startCounting();
});


// Spiner Reviews Carousel

$(document).ready(function() {

    if ($(window).width() > 768) {

            $("div.Timer").addClass("is-running");

            function triggerNext() {
            $('.reviews__carousel').trigger('next.owl.carousel');
            }

            var interval = setInterval(triggerNext, 3990);

            $('.owl-next, .owl-prev').click(function() {
            clearInterval(interval);
            resetAnimations();
            interval = setInterval(triggerNext, 3990);
            });

            function resetAnimations() {
            $('.Timer').removeClass('is-running');
            $('.Timer-mask').css('display', 'none'); // Dodaj tę linię
            setTimeout(function() {
                $('.Timer').addClass('is-running');
                $('.Timer-mask').css('display', ''); // Dodaj tę linię
            }, 0);
            }

    }


  });


// Form

document.addEventListener("DOMContentLoaded", function() {

    var inputFields = document.querySelectorAll("input");

    inputFields.forEach(function(input) {
      input.addEventListener("click", function() {
        input.classList.remove("input-error");
      });
    });

});


// Branże


document.addEventListener("DOMContentLoaded", () => {


        const tabsMenuItem = document.querySelectorAll(".services-tabs__menu-item");
        const wiecejBranzBtn = document.querySelector("#wiecejBranz");
        const wiecejBranzLink = document.querySelector("#wiecejBranzLink");


        tabsMenuItem.forEach((item) => {
            item.addEventListener("click", (event) => {


                let clickTab = event.target.getAttribute("data-ct");

                if(clickTab === "1") {
                    wiecejBranzBtn.innerText = "Więcej o  branży Beauty";
                    wiecejBranzLink.setAttribute("href", "https://kiwikick.pl/wiedza/branze/beauty")
                }

                if(clickTab === "2") {
                    wiecejBranzBtn.innerText = "Więcej o  branży Electronics";
                    wiecejBranzLink.setAttribute("href", "https://kiwikick.pl/wiedza/branze/electronics")
                }

                if(clickTab === "3") {
                    wiecejBranzBtn.innerText = "Więcej o   branży Fashion";
                    wiecejBranzLink.setAttribute("href", "https://kiwikick.pl/wiedza/branze/fashion")
                }

                if(clickTab === "4") {
                    wiecejBranzBtn.innerText = "Zobacz więcej branż";
                    wiecejBranzLink.setAttribute("href", "https://kiwikick.pl/wiedza/branze")
                }


            });
        });





});


